/* App.css */
input:-webkit-autofill {
    -webkit-text-fill-color: white !important;
    -webkit-box-shadow: 0 0 0px calc(var(--unit) * 2) #222 inset !important; /* Change to your desired color */
    background-clip: content-box !important;
}

:root {
  --unit: 20px;
  --color-accent-primary: #ffa500;
  --color-accent-secondary: #ffd000;
  --color-sidebar-plus: #777;

  --patternbg-image: url('images/tile-diagonal.png');
  --patternbg-size: 30px;
  --patternbg-color: #222;

  --font-size-default: calc(var(--unit) * 0.75);
  --font-size-small: calc(var(--unit) * 0.625);
  --font-size-tiny: calc(var(--unit) * 0.5);
  --font-size-title: calc(var(--unit) * 1);
}

@font-face {
  font-family: 'Arial Narrow';
  src: url('./assets/fonts/ArialNarrow.woff2') format('woff2'),
        url('./assets/fonts/ArialNarrow.woff') format('woff'),
        url('./assets/fonts/ArialNarrow.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Arial Narrow';
  src: url('./assets/fonts/ArialNarrowBold.woff2') format('woff2'),
        url('./assets/fonts/ArialNarrowBold.woff') format('woff'),
        url('./assets/fonts/ArialNarrowBold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

::-webkit-scrollbar-track {
  background: rgba(20, 20, 20, 1); /* Black background */
}

::-webkit-scrollbar-thumb {
  background-color: #555; /* Light grey handle */
}

html {
  scrollbar-color: #555 rgba(20, 20, 20, 1); /* Light grey handle and black background */
}

body {
  position: relative;
  background-color: rgba(20, 20, 20, 1);
  color: #ccc;
  text-align: center;
  font-size: var(--font-size-default);
}

select {
  background-color: rgba(0, 0, 0, 0);
  color: #ccc;
  border: none;
  border-color: #555;
  outline: none;
  font-size: var(--font-size-default);
  border-radius: calc(var(--unit) * 0.25);
  margin-inline: calc(var(--unit) * 0.5);
  font-weight: bold;
}

input {
  appearance: none;
  outline: none;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  border-radius: calc(var(--unit) * 0.25);
  font-size: var(--font-size-default);
  height: calc(var(--unit) * 2);
  padding-inline: calc(var(--unit));
}

input, button {
  appearance: none;
  -webkit-appearance: none;
  border-radius: calc(var(--unit) * 2);
  font-size: var(--unit);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

button {
  background-color: var(--color-accent-primary);
  border: none;
  font-weight: bold;
  cursor: pointer;
  transition: background-color var(--animation-speed-standard) ease;
  color: black;
  transition: background-color 0.3s;
  height: calc(var(--unit) * 2);
  padding-inline: var(--unit);
  box-sizing: border-box;
}

select:hover {
  background: rgba(102, 102, 102, 0.25);
}

form {
  display: flex;
  flex-direction: column;
  gap: calc(var(--unit) * 0.5);
}

h2 {
  font-size: var(--font-size-small);
  color: #777;
  margin-top: var(--unit);
  padding-left: calc(var(--unit) * 0.5);
}

.page-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--unit);
}

.textlink {
  color: var(--color-accent-primary);
  cursor: pointer;
  transition: color 0.3s ease;
}

.textlink:hover {
  color: var(--color-accent-secondary);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #000;
  padding: var(--unit);
  border-radius: 5px;
  width: 300px;
  max-width: 80%;
}

.modal-content h2 {
  margin-top: 0;
}

.context-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: calc(var(--unit) * 0.25);
  background-color: rgba(51, 51, 51, 1);
  border: 1px solid #777;
  z-index: 1000;
  min-width: calc(var(--unit) * 10);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  border-radius: calc(var(--unit) * 0.25);
  padding: calc(var(--unit) * 0.25);
}

.context-menu button {
  display: flex;
  padding-inline: calc(var(--unit) * 0.5);
  padding: calc(var(--unit) * 0.25);
  text-align: left;
  background: none;
  border: none;
  cursor: default;
  border-radius: 3px;
  justify-content: space-between;
}

.context-menu button:hover {
  background-color: #777;
}

.context-menu button:disabled {
  background-color: none;
  color: #aaa;
}

.context-menu button:disabled:hover {
  background: none;
}

.context-menu .active {
  background-color: #777;
}

.fullpage-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100dvw;
  height: 100dvh;
  font-size: calc(var(--unit) * 2);
}

.menu-separator {
  height: 1px;
  background-color: #777;
}

.menu-label {
  display: flex;
  flex-direction: row;
}

.checkable-space {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  min-width: calc(var(--unit)a);
  width: calc(var(--unit));
}

.time-signature-format {
  font-size: var(--font-size-tiny);
}

.select-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: calc(var(--unit) * 0.5);
  padding: calc(var(--unit) * 0.25);
  margin-inline: calc(var(--unit) * 0.25);
  font-size: var(--font-size-title);
  font-weight: bold;
  border-radius: calc(var(--unit) * 0.25);
  color: #ccc;
}

.select-view:hover {
  color:#fff
}

.dev-login-overlay {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 1000;
}

.dev-login-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--unit);
  margin: auto;
  background-color: #333;;
  border-radius: var(--unit);
  padding: var(--unit);
}

.row {
  display: flex;
  flex-direction: row;
}

.side-gradient {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 10%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.75), transparent);
  z-index: 2; /* Ensure it's on top of everything */
  display: none; /* Hide by default */
}

.side-gradient.right {
  right: 0;
  background: linear-gradient(to left, rgba(0, 0, 0, 0.75), transparent);
}

.side-gradient.left {
  left: 0;
}

@media (min-width: 1400px) {
  .side-gradient {
    display: block; /* Show gradients on screens 1200px or wider */
  }
}